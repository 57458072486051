import type { AlbumOption } from '@homestyle/shared-data';
import Vue from 'vue';

import { EventBusService } from '../../../shared/services/event-bus.service';
import { AppEvents } from '../../../shared/utils/app-event.enum';

export const GallerySelect = Vue.extend({
  computed: {
    selectOpts: function () {
      const opts: AlbumOption[] = this.options;

      return opts.map((opt, index) => ({
        text: opt.name || `Un-Named-${index}`,
        value: opt.name || `Un-Named-${index}`,
      }));
    },
  },
  methods: {
    getSelection: function () {
      const opts: AlbumOption[] = this.options;
      const selectKey = this.album || this.current;
      const selected = opts.find((opt) => opt.name === selectKey);
      EventBusService.emit({ event: AppEvents.sync, payload: selected });
    },
    updateSelection: function (select: string) {
      this.album = select;
    },
  },
  props: ['selectable', 'options', 'current'],
  render: function (createElement) {
    return createElement('div', { class: 'hs-gallerySelect' }, [
      createElement('v-select', {
        on: { change: this.updateSelection },
        props: { label: 'Select an Album', disabled: this.selectable, items: this.selectOpts, value: this.current },
      }),
      createElement('v-btn', { on: { click: this.getSelection }, props: { color: 'primary', icon: true } }, [
        createElement('v-icon', 'autorenew'),
      ]),
    ]);
  },
});

Vue.component('gallery-select', GallerySelect);

export default GallerySelect;
