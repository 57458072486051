































































































import type { AlbumOption, HTMLObject } from '@homestyle/shared-data';
import { UserRoles } from '@homestyle/shared-data';
import Vue from 'vue';
import { useModule, useStore } from 'vuex-simple';

import GallerySelect from './components/gallery-select';
import { GalleryStore } from '../store';
import { MyStore } from '../../store';
import Editor from '../../shared/containers/Editor';
import PreviewButton from '../../shared/components/PreviewButton';
import {
	EventBusService,
	EventBus,
} from '../../shared/services/event-bus.service';
import { AppEvents } from '../../shared/utils/app-event.enum';

export const GalleryPage = Vue.extend({
	beforeMount: function () {
		if (!this.isPrefetched) {
			const pageName = this.$route.name;
			this.galleryStore.dispatchGalleryPage(pageName).then(() => {
				this.getAlbums();
			});
		}
	},
	created: function () {
		EventBusService.on(AppEvents.sync, this.syncPhotos);
		EventBusService.on(AppEvents.save, this.saveContent);
	},
	components: {
		GallerySelect,
		Editor,
		PreviewButton,
	},
	computed: {
		albums: function () {
			const shared = this.galleryStore.getAlbumOptions;

			return shared;
		},
		isAdmin: function () {
			const role = this.store.currentUser.role;

			return role !== UserRoles.Admin;
		},
		isEdit: function () {
			const role = this.store.currentUser.role;

			return [UserRoles.Admin, UserRoles.Member].includes(role);
		},
		galleryTile: function () {
			return this.$route.name;
		},
		page: function () {
			return this.galleryStore.getGalleryPage;
		},
	},
	data: function () {
		const galleryStore: GalleryStore = useModule(this.$store, ['gallery']);
		const isPrefetched = false;
		const store: MyStore = useStore(this.$store);

		return {
			galleryStore,
			isPrefetched,
			store,
		};
	},
	metaInfo: function () {
		return {
			title: this.$route.meta.title,
		};
	},
	methods: {
		goBack: function () {
			this.$router.push('/gallery');
		},
		getAlbums: function () {
			if (this.isEdit) {
				this.galleryStore.dispatchAlbums();
			}
		},
		saveContent: function (eventBus: EventBus) {
			this.galleryStore.distpatchContent(eventBus.payload as HTMLObject[]);
		},
		syncPhotos: function (eventBus: EventBus) {
			const album = eventBus.payload as AlbumOption;
			this.galleryStore.dispatchPhotos(album);
		},
	},
	serverPrefetch: async function () {
		const pageName = this.$route.name;
		this.$data.isPrefetched = true;

		await this.$data.galleryStore.dispatchGalleryPage(pageName);
	},
	watch: {
		isEdit: function () {
			this.getAlbums();
		},
	},
});

export default GalleryPage;
